import { Injectable } from '@angular/core';
import {
  AlertButton,
  AlertController,
  AlertInput,
  AlertOptions,
  ModalController,
} from '@ionic/angular/standalone';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { KampfErgebnis } from 'src/app/services/kampf/kampf';
import { Spieler } from 'src/app/services/spieler/spieler';
import { LootDisplayDialog } from 'src/app/ui/dialogs/loot-display/loot-display.dialog';

@Injectable({
  providedIn: 'root',
})
export class SpielerverwaltungService {
  private static readonly CANCEL_BUTTON: AlertButton = {
    text: 'Abbechen',
    role: 'cancel',
  };
  private static readonly OK_BUTTON: AlertButton = {
    text: 'OK',
    role: 'ok',
  };
  private static readonly DELETE_BUTTON: AlertButton = {
    text: 'Löschen',
    role: 'destructive',
  };

  constructor(
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  public verfuegbareSpieler(): string[] {
    const spieler: string[] = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i) ?? '';
      if (key.startsWith(Spieler.SAVEGAME_PREFIX)) {
        spieler.push(key.substring(Spieler.SAVEGAME_PREFIX.length));
      }
    }

    return spieler.sort();
  }

  public erfrageNeuenSpielerName(): Promise<string | undefined> {
    return this.alertController
      .create({
        header: 'Neuer Spieler',
        message: 'Gib einen Namen für den neuen Spieler ein:',
        buttons: [
          SpielerverwaltungService.CANCEL_BUTTON,
          SpielerverwaltungService.OK_BUTTON,
        ],
        inputs: [
          {
            type: 'text',
            name: 'name',
            placeholder: 'Spielername',
            label: 'Spielername',
            attributes: {
              onkeyup: (event: KeyboardEvent) => {
                if (event.key == 'Enter') {
                  this.alertController.dismiss(
                    {
                      values: {
                        name: (event.target as HTMLInputElement).value,
                      },
                    },
                    'ok'
                  );
                }
              },
            },
          },
        ],
      })
      .then((dialog) => {
        dialog.present().then(() => {
          dialog.getElementsByTagName('input')[0]?.focus();
        });

        return dialog
          .onDidDismiss<Record<'values', Record<'name', string>>>()
          .then((val) => {
            if (
              val.role == 'ok' &&
              val.data?.values?.name != null &&
              val.data.values.name.length > 0
            ) {
              return val.data.values.name;
            }

            return undefined;
          });
      });
  }

  public waehleSpielerName(
    zumLoeschen: boolean = false
  ): Promise<string | undefined> {
    const options: AlertOptions = {
      header: zumLoeschen ? 'Spieler löschen' : 'Spieler wechseln',
      message: 'Wähle einen Spieler:',
      buttons: [
        SpielerverwaltungService.CANCEL_BUTTON,
        zumLoeschen
          ? SpielerverwaltungService.DELETE_BUTTON
          : SpielerverwaltungService.OK_BUTTON,
      ],
      inputs: [],
    };

    options.inputs = this.verfuegbareSpieler().map((val): AlertInput => {
      return {
        type: 'radio',
        name: 'name',
        label: val,
        value: val,
      };
    });

    if (!zumLoeschen) {
      options.inputs.forEach((input) => {
        input.handler = (elem) => {
          this.alertController.dismiss({ values: elem.value }, 'ok');
        };
      });
    }

    return this.alertController.create(options).then((dialog) => {
      dialog.present();
      return dialog.onDidDismiss<Record<'values', string>>().then((val) => {
        if (
          (val.role == 'ok' || val.role == 'destructive') &&
          val.data?.values != null &&
          val.data.values.length > 0
        ) {
          return val.data.values;
        }

        return undefined;
      });
    });
  }

  public importSpieler(): Promise<Spieler | undefined> {
    return this.alertController
      .create({
        header: 'Importiere Spieler',
        message: 'Gib einen Spielstand an:',
        buttons: [
          SpielerverwaltungService.CANCEL_BUTTON,
          SpielerverwaltungService.OK_BUTTON,
        ],
        inputs: [
          {
            type: 'textarea',
            name: 'data',
            placeholder: 'Spielstand',
            label: 'Spielstand',
            attributes: {
              onkeyup: (event: KeyboardEvent) => {
                if (event.key == 'Enter' && event.ctrlKey) {
                  this.alertController.dismiss(
                    {
                      values: {
                        name: (event.target as HTMLTextAreaElement).value,
                      },
                    },
                    'ok'
                  );
                }
              },
            },
          },
        ],
      })
      .then((dialog) => {
        dialog.present();
        return dialog
          .onDidDismiss<Record<'values', Record<'data', string>>>()
          .then((val) => {
            let imported: Spieler | undefined;

            if (
              val.role == 'ok' &&
              val.data?.values?.data != null &&
              val.data.values.data.length > 0
            ) {
              try {
                imported = new Spieler();

                const importResult: KampfErgebnis | Error = imported.import(
                  val.data.values.data
                );
                if (importResult instanceof Error) {
                  return undefined;
                }

                const loadResult: KampfErgebnis | Error =
                  this.ladeOderErstelleSpieler(imported);
                if (loadResult instanceof Error) {
                  return undefined;
                }

                this.zeigeErgebnis(importResult);
              } catch (err) {
                imported = undefined;
                console.error(err);
              }
            }

            return imported;
          });
      });
  }

  public exportText(exportString?: string): void {
    if (exportString == null) {
      return;
    }

    this.alertController
      .create({
        header: 'Exportiere Spieler',
        message: 'Hier ist dein Spielstand:',
        buttons: [SpielerverwaltungService.OK_BUTTON],
        inputs: [
          {
            type: 'textarea',
            name: 'data',
            placeholder: 'Spielstand',
            label: 'Spielstand',
            value: exportString,
            attributes: {
              readonly: true,
            },
          },
        ],
        htmlAttributes: {
          onkeyup: (event: KeyboardEvent) => {
            if (event.key == 'Enter') {
              this.alertController.dismiss(null, 'ok');
            }
          },
        },
      })
      .then((dialog) => {
        dialog.present();

        try {
          navigator.clipboard.writeText(exportString).catch((reason) => {
            console.log(reason);
          });
        } catch (err) {
          console.log(err);
        }
      });
  }

  public ladeOderErstelleSpielerMitName(name: string): Spieler | undefined {
    const spieler = new Spieler({ name: name });
    const result: KampfErgebnis | Error = this.ladeOderErstelleSpieler(spieler);

    if (result instanceof Error) {
      return undefined;
    }

    return spieler;
  }

  private ladeOderErstelleSpieler(spieler: Spieler): KampfErgebnis | Error {
    const ladenErgebnis: KampfErgebnis | Error = spieler.loadFromSave(
      Spieler.SAVEGAME_PREFIX + spieler.name
    );

    // immer speichern, weil es neuer Spieler oder bestehender Spieler sein kann
    // bei neuen erstellt es den Spieler
    // bei bestehenden verhindert es Rerolls vom Kampf
    this.speichereSpieler(spieler);
    if (ladenErgebnis instanceof KampfErgebnis) {
      this.zeigeErgebnis(ladenErgebnis);
    }

    return ladenErgebnis;
  }

  public speichereSpieler(spieler: Spieler): void {
    spieler.savePlayer(true);
  }

  public loescheSpieler(name: string): void {
    localStorage.removeItem(Spieler.MainCharacterSaveName);
    localStorage.removeItem(Spieler.SAVEGAME_PREFIX + name);
  }

  public zeigeErgebnis(ergebnis?: KampfErgebnis, loot?: ItemReferenz[]): void {
    let sumToShow: number = ergebnis?.killCount ?? 0;
    sumToShow += ergebnis?.expGained ?? 0;
    sumToShow += ergebnis?.loot.length ?? 0;
    sumToShow += loot?.length ?? 0;

    if (sumToShow == 0) {
      // gibt nichts zu zeigen
      return;
    }

    this.modalController
      .create({
        component: LootDisplayDialog,
        componentProps: {
          ergebnis: ergebnis,
          loot: loot,
        },
      })
      .then((modal) => {
        modal.present();
      });
  }
}
