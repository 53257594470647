import { Component, Input, OnInit } from '@angular/core';
import { ItemReferenz } from 'src/app/services/items/item-referenz';
import { KampfErgebnis } from 'src/app/services/kampf/kampf';
import {
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonHeader,
  ModalController,
  IonContent,
} from '@ionic/angular/standalone';
import { ItemListeComponent } from 'src/app/ui/components/item-liste/item-liste.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-loot-display',
  templateUrl: './loot-display.dialog.html',
  styleUrls: ['./loot-display.dialog.scss'],
  standalone: true,
  imports: [
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonIcon,
    IonButton,
    IonButtons,
    ItemListeComponent,
    NgIf,
  ],
})
export class LootDisplayDialog implements OnInit {
  @Input()
  public ergebnis?: KampfErgebnis;

  @Input()
  public loot?: ItemReferenz[];

  public constructor(private modalController: ModalController) {}

  ngOnInit(): void {
    if (this.loot == null) {
      this.loot = this.ergebnis?.loot;
    }
  }

  public schliessen(): void {
    this.modalController.dismiss(null, 'close');
  }
}
