<ion-header>
  <ion-toolbar>
    <ion-title>Loot</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="this.schliessen()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="padding">
    <p *ngIf="this.loot == null && this.ergebnis == null">
      Du hast nichts gefunden, schade...
    </p>

    <p *ngIf="this.ergebnis != null">
      Du hast {{ this.ergebnis.killCount }} Gegner besiegt und {{
      this.ergebnis.expGained.toFixed(1) }} Erfahrung erhalten!
    </p>

    <app-item-liste
      *ngIf="this.loot != null"
      [items]="this.loot"
      [noItemsText]="'Keine Items erhalten.'"
      [placeholderItemCount]="0"
    ></app-item-liste>
  </div>
</ion-content>
